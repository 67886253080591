import React from 'react';
import { graphql } from 'gatsby';
import LayoutDefault from '../../layouts/LayoutDefault';
import PageMetaData from '../../components/layout/PageMetaData';
import { SectionTilesTypesProps } from '../../utils/SectionProps';
import { AllJsonData } from '../../types/graphql';
import { Solution } from '../../types/solution';
import Solutions from '../../components/sections/solutions/Solutions';

interface SolutionsProps extends SectionTilesTypesProps, AllJsonData<Solution, { solutions: [] }> {}

const SolutionsPage: React.FC<SolutionsProps> = ({ data }) => {
  const solutions = data?.solutions.nodes || [];

  return (
    <LayoutDefault>
      <PageMetaData title="Solutions" />
      <Solutions
        solutions={solutions}
        title="Solutions"
        subtitle="Explore a comprehensive range of possibilities to enhance the value of your processes."
        description="The synergy of various Atlassian tools, Marketplace apps, custom-tailored features, and our solid know-how and experience enables us to offer comprehensive solutions for your business growth."
      />
    </LayoutDefault>
  );
};

export default SolutionsPage;

export const query = graphql`
  query SolutionsPage {
    solutions: allJson(filter: { contentName: { eq: "solutions" } }, sort: { fields: order }) {
      nodes {
        id
        title
        description
        image {
          publicURL
        }
      }
    }
  }
`;
